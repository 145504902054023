<template>
  <v-container fluid>
    <v-row
      justify="space-between"
    >
      <v-col>
        <v-btn
          v-if="menuFunctions.SETTING_CLOSE_PERIOD"
          color="primary"
          @click="navigateToPageSettingClosePeriod"
        >
          {{$_strings.closePeriod.ADD_CLOSE_PERIODE}}
        </v-btn>
      </v-col>
      <v-col cols="12" sm="5" md="3">
        <v-text-field
          dense
          hide-details
          outlined
          class="caption"
          :placeholder="$_strings.closePeriod.SEARCH_CLOSE_PERIODE"
          v-model="searchText"
          @keyup.enter="search"
        >
          <template v-slot:prepend-inner>
            <v-icon @click="search"> mdi-magnify </v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :loading="isLoading"
          :headers="displayedHeader"
          :items="items"
          item-key="id"
          :server-items-length="totalItems"
          class="body"
          :options.sync="pagination"
          :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}">
          <template v-slot:[`item.periodName`]={item}>
            <span>{{ item.periodName }}</span>
          </template>
          <template v-slot:[`item.isManual`]={item}>
            <span>{{ item.isManual ? 'Manual' : 'Otomatis' }}</span>
          </template>
          <template v-slot:[`item.intervalPeriod`]={item}>
            <span>{{ item.intervalPeriod }} hari kerja</span>
          </template>
          <template v-slot:[`item.closePeriodDate`]={item}>
            <span>{{ dateFormat(item.closePeriodDate) }}</span>
          </template>
          <template v-slot:[`item.isActive`]={item}>
            <span>{{ item.isActive ? 'Aktif' : 'Tidak Aktif' }}</span>
          </template>
          <template v-slot:[`item.actions`]={item}>
            <v-btn
              v-if="showButtonUpdatePeriod(item)"
              x-small
              color="primary"
              @click="updatePeriodManual(item)"
              @click.stop.prevent
            >
              {{$_strings.closePeriod.CLOSE_MANUAL}}
            </v-btn>
          </template>
          <template v-slot:[`footer.page-text`]="props">
            <span>
              {{$_strings.closePeriod.PAGE_NAME}}
              <span v-if="items.length">
                {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
              </span>
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import {
  dateFormat,
  skipEmptyStringObject,
  handleSortBy,
  handlerPagination,
  defaultPagination,
} from '@/helper/commonHelpers';

export default {
  created() {
    this.getFirstRow();
  },
  data() {
    return {
      isLoading: false,
      items: [],
      searchText: this.$route.query.search || '',
      headers: [
        {
          text: this.$_strings.closePeriod.PERIODE,
          value: 'periodName',
        },
        {
          text: this.$_strings.closePeriod.PERIODE_SETTING,
          value: 'isManual',
        },
        {
          text: this.$_strings.closePeriod.JEDA_CLOSE_PERIOD,
          value: 'intervalPeriod',
        },
        {
          text: this.$_strings.closePeriod.CLOSE_PERIODE_DATE,
          value: 'closePeriodDate',
        },
        {
          text: this.$_strings.closePeriod.STATUS,
          value: 'isActive',
        },
        {
          text: this.$_strings.common.ACTION,
          value: 'actions',
          sortable: false,
        },
      ],
      firstRow: {},
      pagination: this.defaultPagination(),
      totalItems: 0,
    };
  },
  computed: {
    displayedHeader() {
      const canUpdate = this.menuFunctions.SETTING_CLOSE_PERIOD;
      const { canDelete } = this.userAccess;
      return this.headers.map((i) => ({
        ...i,
        class: 'white--text primary text-capitalize',
      })).filter((header) => {
        if (!canUpdate && !canDelete) {
          return header.value !== 'actions';
        }
        return header;
      });
    },
  },
  watch: {
    pagination: {
      handler(newVal) {
        this.handlerPagination(this, newVal);
        if (!this.isLoading) this.fetchClosePeriod();
      },
      deep: true,
    },
  },
  methods: {
    dayjs,
    dateFormat,
    skipEmptyStringObject,
    defaultPagination,
    handlerPagination,
    handleSortBy,
    showButtonUpdatePeriod(item) {
      if (!this.menuFunctions.CLOSE_MANUAL) return;
      const { closePeriodDate } = item;
      const periodMonth = dayjs(closePeriodDate).format('M');
      const currentMonth = dayjs().format('M');
      return this.firstRow.id === item.id && !item.isManual && +currentMonth >= +periodMonth;
    },
    search() {
      if (this.$route.query.search !== this.searchText) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            search: this.searchText,
          },
        });
      }
      if (this.pagination.page > 1) {
        this.pagination.page = 1;
      } else {
        this.fetchClosePeriod();
      }
    },
    async navigateToPageSettingClosePeriod() {
      this.$root.$loading.show();
      try {
        const result = await this.$_services.closePeriod.closePeriodeDefault();
        this.$router.push({
          name: 'setting-close-period',
          params: {
            data: result.data,
          },
        });
      } finally {
        this.$root.$loading.hide();
      }
    },
    async getFirstRow() {
      const filters = {
        page: 0,
        size: 1,
        sort: 'isActive,DESC',
      };
      const res = await this.$_services.closePeriod.closePeriodList(this.skipEmptyStringObject(filters));
      const [firstRow] = res.data.contents;
      if (firstRow) {
        this.firstRow = firstRow;
      }
    },
    async fetchClosePeriod() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filters = {
        page: page - 1,
        size: itemsPerPage,
        sort: this.handleSortBy({ defaultValue: 'isActive,DESC', sortBy, sortDesc }),
        periodName: '',
      };
      if (this.searchText) filters.periodName = `qLike(${this.searchText})`;
      try {
        this.isLoading = true;
        const result = await this.$_services.closePeriod.closePeriodList(this.skipEmptyStringObject(filters));
        this.items = result.data.contents;
        this.totalItems = result.data.totalData;
      } finally {
        this.isLoading = false;
      }
    },
    updatePeriodManual(item) {
      this.$dialog.warning({
        text: `Apakah anda yakin ingin mengakhiri period "${item.periodName}" ?`,
        title: this.$_strings.common.WARNING,
        persistent: true,
        actions: {
          false: this.$_strings.common.NO,
          true: this.$_strings.common.YES,
        },
      }).then((res) => {
        if (!res) return;
        this.$root.$loading.show();
        this.$_services.closePeriod.updatePeriodManual(item.id)
          .then(() => {
            this.$dialog.notify.success(`Berhasil mengakhiri period "${item.periodName}"`);
            this.firstRow = {};
            this.getFirstRow();
            this.fetchClosePeriod();
          }).finally(() => {
            this.$root.$loading.hide();
          });
      });
    },
  },
};
</script>
