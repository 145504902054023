var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',[(_vm.menuFunctions.SETTING_CLOSE_PERIOD)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.navigateToPageSettingClosePeriod}},[_vm._v(" "+_vm._s(_vm.$_strings.closePeriod.ADD_CLOSE_PERIODE)+" ")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"5","md":"3"}},[_c('v-text-field',{staticClass:"caption",attrs:{"dense":"","hide-details":"","outlined":"","placeholder":_vm.$_strings.closePeriod.SEARCH_CLOSE_PERIODE},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search($event)}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{on:{"click":_vm.search}},[_vm._v(" mdi-magnify ")])]},proxy:true}]),model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"body",attrs:{"loading":_vm.isLoading,"headers":_vm.displayedHeader,"items":_vm.items,"item-key":"id","server-items-length":_vm.totalItems,"options":_vm.pagination,"footer-props":{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': _vm.$_item_per_page}},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.periodName",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.periodName))])]}},{key:"item.isManual",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.isManual ? 'Manual' : 'Otomatis'))])]}},{key:"item.intervalPeriod",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.intervalPeriod)+" hari kerja")])]}},{key:"item.closePeriodDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.dateFormat(item.closePeriodDate)))])]}},{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.isActive ? 'Aktif' : 'Tidak Aktif'))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.showButtonUpdatePeriod(item))?_c('v-btn',{attrs:{"x-small":"","color":"primary"},on:{"click":[function($event){return _vm.updatePeriodManual(item)},function($event){$event.stopPropagation();$event.preventDefault();}]}},[_vm._v(" "+_vm._s(_vm.$_strings.closePeriod.CLOSE_MANUAL)+" ")]):_vm._e()]}},{key:"footer.page-text",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(_vm.$_strings.closePeriod.PAGE_NAME)+" "),(_vm.items.length)?_c('span',[_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" of "+_vm._s(props.itemsLength)+" ")]):_vm._e()])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }